import * as _http2 from "http";
var _http = "default" in _http2 ? _http2.default : _http2;
import * as _https2 from "https";
var _https = "default" in _https2 ? _https2.default : _https2;
import * as _url2 from "url";
var _url = "default" in _url2 ? _url2.default : _url2;
var exports = {};
const http = _http;
const https = _https;
const urlUtil = _url;
exports.default = options => {
  if (options.fetcher) {
    return options.fetcher(options.uri);
  }
  return new Promise((resolve, reject) => {
    const {
      hostname,
      path,
      port,
      protocol
    } = urlUtil.parse(options.uri);
    const requestOptions = {
      hostname,
      path,
      port,
      method: "GET",
      ...(options.headers && {
        headers: {
          ...options.headers
        }
      }),
      ...(options.timeout && {
        timeout: options.timeout
      }),
      ...(options.agent && {
        agent: options.agent
      })
    };
    const httpRequestLib = protocol === "https:" ? https : http;
    const httpRequest = httpRequestLib.request(requestOptions, res => {
      let rawData = "";
      res.setEncoding("utf8");
      res.on("data", chunk => {
        rawData += chunk;
      });
      res.on("end", () => {
        if (res.statusCode < 200 || res.statusCode >= 300) {
          const errorMsg = res.body && (res.body.message || res.body) || res.statusMessage || `Http Error ${res.statusCode}`;
          reject({
            errorMsg
          });
        } else {
          try {
            resolve(rawData && JSON.parse(rawData));
          } catch (error) {
            reject(error);
          }
        }
      });
    });
    httpRequest.on("timeout", () => httpRequest.destroy()).on("error", e => reject(e)).end();
  });
};
export default exports;