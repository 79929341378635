var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
function ArgumentError(message) {
  Error.call(this || _global, message);
  Error.captureStackTrace(this || _global, (this || _global).constructor);
  (this || _global).name = "ArgumentError";
  (this || _global).message = message;
}
ArgumentError.prototype = Object.create(Error.prototype);
ArgumentError.prototype.constructor = ArgumentError;
exports = ArgumentError;
export default exports;