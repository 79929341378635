var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
function JwksRateLimitError(message) {
  Error.call(this || _global, message);
  Error.captureStackTrace(this || _global, (this || _global).constructor);
  (this || _global).name = "JwksRateLimitError";
  (this || _global).message = message;
}
JwksRateLimitError.prototype = Object.create(Error.prototype);
JwksRateLimitError.prototype.constructor = JwksRateLimitError;
exports = JwksRateLimitError;
export default exports;