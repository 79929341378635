import * as _jose2 from "jose";
var _jose = "default" in _jose2 ? _jose2.default : _jose2;
import * as _crypto2 from "crypto";
var _crypto = "default" in _crypto2 ? _crypto2.default : _crypto2;
var exports = {};
const jose = _jose;
const crypto = _crypto;
async function retrieveSigningKeys(jwks) {
  const results = [];
  jwks = jwks.filter(({
    use
  }) => use === "sig" || use === undefined).filter(({
    kty
  }) => kty === "RSA" || kty === "EC" || kty === "OKP");
  for (const jwk of jwks) {
    try {
      // The algorithm is actually not used in the Node.js KeyObject-based runtime
      // passing an arbitrary value here and checking that KeyObject was returned
      // later
      const keyObject = await jose.importJWK(jwk, "RS256");
      if (!(keyObject instanceof crypto.KeyObject) || keyObject.type !== "public") {
        continue;
      }
      const getSpki = () => keyObject.export({
        format: "pem",
        type: "spki"
      });
      results.push({
        get publicKey() {
          return getSpki();
        },
        get rsaPublicKey() {
          return getSpki();
        },
        getPublicKey() {
          return getSpki();
        },
        ...(typeof jwk.kid === "string" && jwk.kid ? {
          kid: jwk.kid
        } : undefined),
        ...(typeof jwk.alg === "string" && jwk.alg ? {
          alg: jwk.alg
        } : undefined)
      });
    } catch (err) {
      continue;
    }
  }
  return results;
}
exports = {
  retrieveSigningKeys
};
export default exports;