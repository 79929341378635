var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
function JwksError(message) {
  Error.call(this || _global, message);
  Error.captureStackTrace(this || _global, (this || _global).constructor);
  (this || _global).name = "JwksError";
  (this || _global).message = message;
}
JwksError.prototype = Object.create(Error.prototype);
JwksError.prototype.constructor = JwksError;
exports = JwksError;
export default exports;