import _JwksClient from "./JwksClient";
import _errors from "./errors";
import _hapi from "./integrations/hapi";
import _express from "./integrations/express";
import _koa from "./integrations/koa";
import _passport from "./integrations/passport";
var exports = {};
const {
  JwksClient
} = _JwksClient;
const errors = _errors;
const {
  hapiJwt2Key,
  hapiJwt2KeyAsync
} = _hapi;
const {
  expressJwtSecret
} = _express;
const {
  koaJwtSecret
} = _koa;
const {
  passportJwtSecret
} = _passport;
exports = options => {
  return new JwksClient(options);
};
exports.JwksClient = JwksClient;
exports.ArgumentError = errors.ArgumentError;
exports.JwksError = errors.JwksError;
exports.JwksRateLimitError = errors.JwksRateLimitError;
exports.SigningKeyNotFoundError = errors.SigningKeyNotFoundError;
exports.expressJwtSecret = expressJwtSecret;
exports.hapiJwt2Key = hapiJwt2Key;
exports.hapiJwt2KeyAsync = hapiJwt2KeyAsync;
exports.koaJwtSecret = koaJwtSecret;
exports.passportJwtSecret = passportJwtSecret;
export default exports;
export const ArgumentError = exports.ArgumentError,
  JwksError = exports.JwksError,
  JwksRateLimitError = exports.JwksRateLimitError,
  SigningKeyNotFoundError = exports.SigningKeyNotFoundError;
const _JwksClient2 = exports.JwksClient,
  _expressJwtSecret = exports.expressJwtSecret,
  _hapiJwt2Key = exports.hapiJwt2Key,
  _hapiJwt2KeyAsync = exports.hapiJwt2KeyAsync,
  _koaJwtSecret = exports.koaJwtSecret,
  _passportJwtSecret = exports.passportJwtSecret;
export { _JwksClient2 as JwksClient, _expressJwtSecret as expressJwtSecret, _hapiJwt2Key as hapiJwt2Key, _hapiJwt2KeyAsync as hapiJwt2KeyAsync, _koaJwtSecret as koaJwtSecret, _passportJwtSecret as passportJwtSecret };