import * as _util2 from "util";
var _util = "default" in _util2 ? _util2.default : _util2;
var exports = {};
const {
  callbackify
} = _util;
const callbackSupport = client => {
  const getSigningKey = client.getSigningKey.bind(client);
  return (kid, cb) => {
    if (cb) {
      const callbackFunc = callbackify(getSigningKey);
      return callbackFunc(kid, cb);
    }
    return getSigningKey(kid);
  };
};
exports.default = callbackSupport;
export default exports;