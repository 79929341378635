import * as _debug2 from "debug";
var _debug = "default" in _debug2 ? _debug2.default : _debug2;
import * as _limiter2 from "limiter";
var _limiter = "default" in _limiter2 ? _limiter2.default : _limiter2;
import _JwksRateLimitError from "../errors/JwksRateLimitError";
var exports = {};
const logger = _debug("jwks");
const {
  RateLimiter
} = _limiter;
const JwksRateLimitError = _JwksRateLimitError;
function rateLimitWrapper(client, {
  jwksRequestsPerMinute = 10
}) {
  const getSigningKey = client.getSigningKey.bind(client);
  const limiter = new RateLimiter(jwksRequestsPerMinute, "minute", true);
  logger(`Configured rate limiting to JWKS endpoint at ${jwksRequestsPerMinute}/minute`);
  return async kid => await new Promise((resolve, reject) => {
    limiter.removeTokens(1, async (err, remaining) => {
      if (err) {
        reject(err);
      }
      logger("Requests to the JWKS endpoint available for the next minute:", remaining);
      if (remaining < 0) {
        logger("Too many requests to the JWKS endpoint");
        reject(new JwksRateLimitError("Too many requests to the JWKS endpoint"));
      } else {
        try {
          const key = await getSigningKey(kid);
          resolve(key);
        } catch (error) {
          reject(error);
        }
      }
    });
  });
}
exports.default = rateLimitWrapper;
export default exports;