import * as _debug2 from "debug";
var _debug = "default" in _debug2 ? _debug2.default : _debug2;
import * as _lruMemoizer2 from "lru-memoizer";
var _lruMemoizer = "default" in _lruMemoizer2 ? _lruMemoizer2.default : _lruMemoizer2;
import * as _util2 from "util";
var _util = "default" in _util2 ? _util2.default : _util2;
var exports = {};
const logger = _debug("jwks");
const memoizer = _lruMemoizer;
const {
  promisify,
  callbackify
} = _util;
function cacheWrapper(client, {
  cacheMaxEntries = 5,
  cacheMaxAge = 600000
}) {
  logger(`Configured caching of signing keys. Max: ${cacheMaxEntries} / Age: ${cacheMaxAge}`);
  return promisify(memoizer({
    hash: kid => kid,
    load: callbackify(client.getSigningKey.bind(client)),
    maxAge: cacheMaxAge,
    max: cacheMaxEntries
  }));
}
exports.default = cacheWrapper;
export default exports;