var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
function SigningKeyNotFoundError(message) {
  Error.call(this || _global, message);
  Error.captureStackTrace(this || _global, (this || _global).constructor);
  (this || _global).name = "SigningKeyNotFoundError";
  (this || _global).message = message;
}
SigningKeyNotFoundError.prototype = Object.create(Error.prototype);
SigningKeyNotFoundError.prototype.constructor = SigningKeyNotFoundError;
exports = SigningKeyNotFoundError;
export default exports;